import { AddHighlightTypes } from './utils.types';

const addHighlightBold700 = (string: AddHighlightTypes) => {
  const regexHighlightBold = string?.replace(
    /\[(.*?)\]/g,
    '<span style="font-weight: 700; text-wrap: pretty;"}>$1</span>',
  );
  return regexHighlightBold;
};

export default addHighlightBold700;
