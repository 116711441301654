const isFullUrl = ({ url }: { url: string }) => {
  try {
    const parsedUrl = new URL(url);
    // Check if the URL has a protocol and host
    return parsedUrl.protocol && parsedUrl.host;
  } catch (error) {
    // An error will be thrown if the URL is invalid (likely a slug)
    return false;
  }
};

export default isFullUrl;
